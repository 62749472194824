import React from 'react';

const Page1 = () => {
  return (
    <div style={{ color: 'white', textAlign: 'center', paddingTop: '50px' }}>
      <h1>Mixing Lab</h1>
      <p>Coming Soon</p>
    </div>
  );
};

export default Page1;
