import { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useWalletContext } from "../context/WalletProvider";
import { useWallet } from "../context/WalletContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Wallet = () => {
  const {
    connected,
    connectWallet,
    connectUnisatWallet,
    disconnectWallet,
    usersTaprootAddress,
    connectXverseWallet,
  } = useWalletContext();

  const { setUsersTaprootAddress } = useWallet();

  const [points, setPoints] = useState(null);

  useEffect(() => {
    if (connected) {
      const fetchPoints = async () => {
        try {
          const response = await fetch(
            `https://gcc5295c8e4096b-pp.adb.eu-frankfurt-1.oraclecloudapps.com/ords/e9/points/yield?wallet=${usersTaprootAddress}`
          );
          const data = await response.json();
          if (data.items && data.items.length > 0) {
            setPoints(data.items[0].points);
          }
        } catch (error) {
          console.error("Error fetching points:", error);
        }
      };

      fetchPoints();
      setUsersTaprootAddress(usersTaprootAddress); // Set address in context
    }
  }, [connected, usersTaprootAddress, setUsersTaprootAddress]);

  const goToDashboard = () => {
    window.location.href = "/dashboard";
  };

  return (
    <>
      {!connected ? (
        <Menu
          as="div"
          className="relative inline-block text-left text-xs md:text-lg"
        >
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 bg-orange px-3 py-2 kode-mono-2p-regular text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              CONNECT
              <ChevronDownIcon
                className="-mr-1 h-4 w-4 text-black md:h-7 md:w-7"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1 kode-mono-2p-regular">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-orange text-gray-900" : "bg-white",
                        "block w-full px-4 py-2 text-black"
                      )}
                      onClick={connectWallet}
                    >
                      Leather
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-orange text-gray-900" : "bg-white",
                        "block w-full px-4 py-2 text-black"
                      )}
                      onClick={connectXverseWallet}
                    >
                      Xverse
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-orange text-gray-900" : "bg-white",
                        "block w-full px-4 py-2 text-black"
                      )}
                      onClick={connectUnisatWallet}
                    >
                      Unisat
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <Menu
          as="div"
          className="relative inline-block text-left text-xs md:text-lg"
        >
          <div className="flex items-center">
            {points !== null && (
              <div className="mr-4 kode-mono-2p-regular text-orange flex-nowrap min-w-[100px] md:min-w-[200px]">
                Points: {points}
              </div>
            )}
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 bg-orange px-3 py-2 kode-mono-2p-regular text-black shadow-sm ring-1 ring-inset hover:text-gray-200">
              ...{usersTaprootAddress.slice(-5)}
              <ChevronDownIcon
                className="-mr-1 h-4 w-4 text-black md:h-7 md:w-7"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right bg-custom-color ring-black ring-opacity-5 focus:outline-none bg-white">
              <div className="py-1 kode-mono-2p-regular">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? "bg-orange text-gray-900" : "text-black",
                        "block w-full px-4 py-2 bg-white"
                      )}
                      onClick={disconnectWallet}
                    >
                      DISCONNECT
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </>
  );
};

export default Wallet;
