import React from "react";

function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center">
      {" "}
      <div className="mt-10 text-center kode-mono-2p-regular text-white">
        <h1 className="mb-4 text-4xl font-bold">404 - Page Not Found</h1>
        <p>This page does not exist.</p>
      </div>
    </div>
  );
}

export default NotFoundPage;
