import React from 'react';
import provenance from './images/provenance.jpeg';
import sat from './images/sat.png';

const Page1 = () => {
  return (
    <div style={{ color: 'white', textAlign: 'center', paddingTop: '50px' }}>
      <h1>Studio Info</h1>
      <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', textAlign: 'left' }}>
        <p>
          A Good Doctor Studio is the visionary creative force behind Experiment9, a social experiment focused on pioneering Web3, digital collectibles, on-chain gaming, animation, and global brand development. Our mission is to push the boundaries of what's possible in the digital landscape, driving innovation and crafting transformative experiences for audiences worldwide.
        </p>
        <br />

        <p>
          At A Good Doctor Studio, we are committed to advancing the future of the online world and remain dedicated to bringing our bold ideas to life, continually evolving and shaping the status quo.
        </p>
        <br />
        <p>Our first adventure of many takes place on the canvas known as ordinals.</p>
        <br />
        <h2>Overview</h2>
        <p>
          One of the unique properties of Ordinals is that each ordinal has a name, consisting of letters A - Z. In some cases these letters that emerge from a particular ordinal spell a name or phrase that can be used to create a point of reference or story for a collection or creation.
        </p>
        <br />

        <p>
          "A Good Doctor" is the named sat and parent inscription for Experiment 9 (E9) and all future Experiments created under Inscription 70826886.
        </p>
        <br />

        <p>
          "A Good Doctor" is never seen, and only known by name.
        </p>
        <br />
        
        <h3>Named Parent Sat</h3>
        <p>
          View "A Good Doctor" Named Sat <a href="https://ordinals.com/sat/1917572203052608" target="_blank" rel="noopener noreferrer">Here</a>
        </p>
        <br />
        <br />
        
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <img src={sat} alt="Sat" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
        
        <h3>Parent/Child Provenance</h3>
        <p>
          All onchain creations must have provenance in order to trace the origin and evolution of inscriptions. The properties of Ordinals allows for onchain hierarchies to emerge as new content is inscribed under a parent.
        </p>
        <br />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <img src={provenance} alt="Provenance" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
    </div>
  );
};

export default Page1;
