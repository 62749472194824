import React from 'react';
import image1 from './images/1.gif';
import image2 from './images/2.png';
import image3 from './images/3.gif';
import image4 from './images/4.gif';
import image5 from './images/5.gif';
import image6 from './images/6.gif';
import image7 from './images/7.gif';
import image8 from './images/8.gif';
import image9 from './images/9.gif';

const Page1 = () => {
  return (
    <div style={{ color: 'white', textAlign: 'center', paddingTop: '50px' }}>
      <h1>artwork</h1>
      <div 
        style={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(3, 1fr)', 
          gap: '20px', 
          justifyItems: 'center',
          width: '75%',
          margin: '0 auto'
        }}
      >
        <img src={image1} alt="Artwork 1" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <img src={image2} alt="Artwork 2" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <img src={image3} alt="Artwork 3" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <img src={image4} alt="Artwork 4" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <img src={image5} alt="Artwork 5" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <img src={image6} alt="Artwork 6" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <img src={image7} alt="Artwork 7" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <img src={image8} alt="Artwork 8" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <img src={image9} alt="Artwork 9" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
      </div>
    </div>
  );
};

export default Page1;
