import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Header from "./components/header";
import WalletProvider from "./context/WalletProvider";
import { NotificationProvider } from "./context/NotificationContext";
import NotFoundPage from "./pages/404";
import Home from "./components/Home";
import React from 'react';
import Page1 from './pages/experiment9';
import Page2 from './pages/inscriptions';
import Page3 from './pages/artwork';
import Page4 from './pages/info';
import Page5 from './pages/mixinglab';
import Page6 from './pages/holdings';

function App() {
  return (
    <NotificationProvider>
      <WalletProvider>
      <Header />
        <div className="app-container flex min-h-screen flex-col bg-black">
          
          <main className="flex-grow bg-black">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/experiment9" element={<Page1 />} />
              <Route path="/inscriptions" element={<Page2 />} />
              <Route path="/artwork" element={<Page3 />} />
              <Route path="/info" element={<Page4 />} />
              <Route path="/mixinglab" element={<Page5 />} />
              <Route path="/holdings" element={<Page6 />} />
              <Route path="/*" element={<NotFoundPage />} />
            </Routes>
          </main>
          <footer className="bottom-0 mt-16 w-full bg-black p-4 text-center kode-mono-2p-regular text-white">
            &copy; {new Date().getFullYear()} A GOOD DOCTOR
          </footer>
        </div>
      </WalletProvider>
    </NotificationProvider>
  );
}

export default App;
