import React, { createContext, useContext, useState, useEffect } from 'react';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [usersTaprootAddress, setUsersTaprootAddress] = useState(null);

  return (
    <WalletContext.Provider value={{ usersTaprootAddress, setUsersTaprootAddress }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
