import React from 'react';
import '../components/index.css';
import image1 from './images/2.png';

const Page1 = () => {
  return (
    <div style={{ color: 'white', textAlign: 'left', paddingTop: '50px', maxWidth: '800px', margin: '0 auto' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <h1>Experiment 9</h1>
          <h2>420 rare creations held on block9s</h2>
        </div>
        <img src={image1} alt="Artwork 1" style={{ height: 'auto', maxHeight: '100px', objectFit: 'cover' }} />
      </div>
      <div style={{ textAlign: 'left', marginTop: '20px' }}>
        <p>Once upon a time, in a universe woven with wonder and whimsy, there lived a brilliant alchemist known only as AGOODDOCTOR. One fateful day, AGOODDOCTOR embarked on a grand experiment that would forever change the fabric of his world. From the bubbling cauldron of his lab emerged 420 mischievous creatures, each crafted from the same potent parent chemical. These delightful beings, known as the E9s, were brimming with playful energy, always ready for a new adventure.</p>
        <br />
        <p>However, the universe is a place of balance, and where there is light, there must also be shadow. At the very same moment the E9s were born, a cursed parent concocted a brood of sinister creatures. These dark beings, driven by malice, set out with a single purpose: to destroy the joyous E9s and put an end to their endless fun.</p>
        <br />
        <p>Amidst the 420 E9s, twelve stood apart from their playful brethren. These twelve were different; they were strong, righteous, and possessed a deep sense of duty. They were the Guardians, destined to protect their mischievous kin from the lurking threats of the cursed creations. Each Guardian commanded a unique realm within the expansive AGOODDOCTOR universe, standing as vigilant sentinels against the darkness.</p>
        <br />
        <p>The mischievous E9s, unaware or perhaps simply unconcerned about the looming danger, continued to live their carefree lives. Their days were filled with pranks and laughter, their hearts light as feathers. No threat could dampen their spirits or deter them from their cheeky escapades.</p>
        <br />
        <p>Yet, the Guardians knew the peril that lurked in the shadows. Time and time again, they faced the cursed creations, fighting valiantly to protect the E9s' joyful existence. These brave twelve realized that their strength alone might not be enough. They would need allies, new friends who could help them in their tireless quest to keep the world safe.</p>
        <br />
        <p>And so, the Guardians began to seek out new heroes, beings of strength and virtue who could join their cause. They ventured into uncharted realms and ancient forests, across vast deserts and deep into the heart of mysterious cities, always searching for those who could aid them in their fight.</p>
        <br />
        <p>What adventures would the naughty E9s embark on next? How would they navigate the treacherous path that lay before them? And who would rise to stand alongside the Guardians in their noble struggle to protect their carefree kin?</p>
        <br />
        <p>In the AGOODDOCTOR universe, the story had only just begun, filled with the promise of countless adventures, unexpected alliances, and the eternal dance between light and darkness. The mischievous E9s would continue their playful pranks, the Guardians would remain ever vigilant, and new heroes would emerge, ready to defend the joyous spirit of this magical world.</p>
      </div>
    </div>
  );
};

export default Page1;
