import React from 'react';
import experimentList from './images/experiment_list.png'; // Ensure the correct path to your image

const Page1 = () => {
  return (
    <div style={{ color: 'white', textAlign: 'center', paddingTop: '50px', maxHeight: '100vh', overflowY: 'auto' }}>
      <h1>Inscriptions</h1>
      <br />
      <div style={{ marginBottom: '20px' }}>
        <a href="https://www.ord.io/?sortBy=newest&childrenOf=8172ad05cd6e3f9a7652d36ef8855c9d0aec31a51ff6636c85e3fb3c73595597i0" target="_blank" rel="noopener noreferrer">
          <img src={experimentList} alt="Experiment List" style={{ width: '33vw', height: 'auto', display: 'block', margin: '0 auto' }} />
        </a>
      </div>
      <div style={{ width: '100%', height: '500px', position: 'relative', marginBottom: '20px' }}>
        <iframe
          sandbox="allow-scripts"
          title="Inscription Iframe 1"
          className="aspect-square h-full w-full overflow-visible bg-background"
          loading="lazy"
          width="100%"
          height="100%"
          draggable="false"
          src="https://ordin-delta.vercel.app/content/e24c2170951f6f929b5d1cc5e5401c4b16d8b0034b07e395701e9af73b5902e4i0"
          style={{ border: 'none', position: 'absolute', top: 0, left: 0 }}
        ></iframe>
      </div>
      <div style={{ width: '100%', height: '500px', position: 'relative', marginBottom: '20px' }}>
        <iframe
          sandbox="allow-scripts"
          title="Inscription Iframe 2"
          className="aspect-square h-full w-full overflow-visible bg-background"
          loading="lazy"
          width="100%"
          height="100%"
          draggable="false"
          src="https://ordin-delta.vercel.app/content/4679a6549f319761415184a287816bca7d088e5eaf0c620b09987dabbafe4600i0"
          style={{ border: 'none', position: 'absolute', top: 0, left: 0 }}
        ></iframe>
      </div>
      <div style={{ width: '100%', height: '800px', position: 'relative', marginBottom: '20px' }}>
        <iframe
          sandbox="allow-scripts"
          title="Inscription Iframe 3"
          className="aspect-square h-full w-full overflow-visible bg-background"
          loading="lazy"
          width="100%"
          height="100%"
          draggable="false"
          src="https://ordin-delta.vercel.app/content/d2b6a03a1c980c6447a96993ad6565d1dfdcfa0b35b34f26237910ac0409d491i0"
          style={{ border: 'none', position: 'absolute', top: 0, left: 0 }}
        ></iframe>
      </div>
      <div style={{ width: '100%', height: '500px', position: 'relative', marginBottom: '20px' }}>
      </div>
    </div>
  );
};

export default Page1;
