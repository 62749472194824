import React, { useState, useEffect } from 'react';
import { useWallet } from '../context/WalletContext';
import fomojis from './images/fomojis.webp';
import pizzaNinjas from './images/pizza-ninjas.webp';
import ordzaarPass from './images/ordzaar.webp';
import bitcoinFrogs from './images/frogs.png';
import ocmGenesis from './images/ocm.gif';
import wizardsOfOrd from './images/wizards.png';
import blob from './images/blob.png';
import contract from './images/contract.jpg';

const Page1 = () => {
  const { usersTaprootAddress } = useWallet();
  const [holdings, setHoldings] = useState([]);

  useEffect(() => {
    if (usersTaprootAddress) {
      const fetchHoldings = async () => {
        try {
          const response = await fetch(
            `https://gcc5295c8e4096b-pp.adb.eu-frankfurt-1.oraclecloudapps.com/ords/e9/points/holdings?wallet=${usersTaprootAddress}`
          );
          const data = await response.json();
          if (data.items) {
            setHoldings(data.items);
          }
        } catch (error) {
          console.error('Error fetching holdings:', error);
        }
      };

      fetchHoldings();
    }
  }, [usersTaprootAddress]);

  const getImageForProject = (project) => {
    switch (project) {
      case 'Fomojis':
        return fomojis;
      case 'Pizza Ninjas':
        return pizzaNinjas;
      case 'Ordzaar Pass':
        return ordzaarPass;
      case 'Bitcoin Frogs':
        return bitcoinFrogs;
      case 'OCM Genesis':
        return ocmGenesis;
      case 'The Wizards of Ord':
        return wizardsOfOrd;
      case 'Blob':
        return blob;
      case 'E9 Contract':
        return contract
      default:
        return null;
    }
  };

  return (
    <div style={{ color: 'white', textAlign: 'center', paddingTop: '50px' }}>
      <h1>Holdings</h1>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {holdings.length > 0 ? (
          holdings.map((holding, index) => (
            <div 
              key={index} 
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                marginBottom: '20px',
                width: '50%',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ textAlign: 'left' }}>
                <p>{holding.project}</p>
                <p>Held: {holding.held}</p>
              </div>
              {getImageForProject(holding.project) && (
                <img
                  src={getImageForProject(holding.project)}
                  alt={holding.project}
                  style={{ width: '100px', height: '100px' }}
                />
              )}
            </div>
          ))
        ) : (
          <p>No partner NFTs detected</p>
        )}
      </div>
    </div>
  );
};

export default Page1;
