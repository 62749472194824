import React, { useState } from "react";
import Wallet from "./wallet";
import NotificationButton from "./NotificationButton";
import { NETWORK } from "../config";

const notifications = [];

const handleLinkClick = (path) => {
  window.location.href = path;
};

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className="flex items-center justify-between p-4 text-white relative">
      <div
        id="title"
        className="flex items-center justify-center kode-mono-2p-regular font-bold text-orange text-shadow-black"
      >
        <ul className="flex flex-row gap-12">
          <li>
            <a href="/">
              <div> A GOOD DOCTOR</div>
            </a>
          </li>
        </ul>
      </div>
      <div className="flex items-center space-x-4">
        <NotificationButton notifications={notifications} />
        <Wallet />
        <button className="md:hidden" onClick={toggleDropdown}>
          ☰
        </button>
      </div>
      <div className={`fixed right-0 top-20 h-full flex flex-col space-y-4 p-4 bg-black bg-opacity-70 kode-mono-2p-regular text-orange text-shadow-black md:flex ${dropdownOpen ? 'block' : 'hidden'}`}>
        <div className="cursor-pointer" onClick={() => handleLinkClick("/experiment9")}>
          Experiment 9
        </div>
        <div className="cursor-pointer" onClick={() => handleLinkClick("/inscriptions")}>
          Inscriptions
        </div>
        <div className="cursor-pointer" onClick={() => handleLinkClick("/artwork")}>
          Artwork
        </div>
        <div className="cursor-pointer" onClick={() => handleLinkClick("/info")}>
          Studio Info
        </div>
        <div className="cursor-pointer" onClick={() => handleLinkClick("/mixinglab")}>
          Mixing Lab (Coming Soon)
        </div>
        <div className="cursor-pointer" onClick={() => handleLinkClick("/holdings")}>
          Holdings
        </div>
      </div>
    </header>
  );
};

export default Header;
