import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import './index.css';

const MatrixEffect = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const letters = "AGOODDOCTOR";
    const fontSize = 8;
    const columns = canvas.width / fontSize;
    const drops = Array.from({ length: Math.floor(columns) }, () => Math.floor(Math.random() * canvas.height / fontSize));

    const draw = () => {
      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = "orange";
      ctx.font = `${fontSize}px arial`;

      for (let i = 0; i < drops.length; i++) {
        const text = letters[Math.floor(Math.random() * letters.length)];
        ctx.fillText(text, i * fontSize, drops[i] * fontSize);

        if (drops[i] * fontSize > canvas.height && Math.random() > 0.975) {
          drops[i] = 0;
        }

        drops[i]++;
      }
    };

    const intervalId = setInterval(draw, 50);

    return () => clearInterval(intervalId);
  }, []);

  return <canvas ref={canvasRef} style={{ display: "block", background: "black" }} />;
};

const Cube = () => {
  const cubeRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const cube = cubeRef.current;
    let mouseX = 0;
    let mouseY = 0;
    let cubeX = window.innerWidth / 2;
    let cubeY = window.innerHeight / 2;
    let isTouching = false;

    const isMobile = window.innerWidth <= 768;

    const onMouseMove = (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    };

    const onTouchMove = (e) => {
      if (e.touches.length > 0) {
        const touch = e.touches[0];
        mouseX = touch.clientX;
        mouseY = touch.clientY;
      }
    };

    const onTouchStart = (e) => {
      if (e.touches.length > 0) {
        const touch = e.touches[0];
        mouseX = touch.clientX;
        mouseY = touch.clientY;
        isTouching = true;
      }
    };

    const onTouchEnd = () => {
      isTouching = false;
    };

    const updateCube = () => {
      cubeX += (mouseX - cubeX) * 0.05;
      cubeY += (mouseY - cubeY) * 0.05;

      // Invert the vertical axis
      cube.style.transform = `rotateY(${cubeX / window.innerWidth * 360}deg) rotateX(${cubeY / window.innerHeight * 360}deg)`;

      requestAnimationFrame(updateCube);
    };

    updateCube();

    const handleGlobalClick = (e) => {
      if (!cube.contains(e.target)) {
        mouseX = e.clientX;
        mouseY = e.clientY;
      }
    };

    const handleGlobalTouchEnd = (e) => {
      if (!cube.contains(e.target)) {
        if (e.touches.length > 0) {
          const touch = e.touches[0];
          mouseX = touch.clientX;
          mouseY = touch.clientY;
        }
      }
    };

    window.addEventListener("click", handleGlobalClick);
    window.addEventListener("touchend", handleGlobalTouchEnd);

    if (isMobile) {
      window.addEventListener("touchmove", onTouchMove);
      window.addEventListener("touchstart", onTouchStart);
      window.addEventListener("touchend", onTouchEnd);
    } else {
      window.addEventListener("mousemove", onMouseMove);
    }

    return () => {
      window.removeEventListener("click", handleGlobalClick);
      window.removeEventListener("touchend", handleGlobalTouchEnd);

      if (isMobile) {
        window.removeEventListener("touchmove", onTouchMove);
        window.removeEventListener("touchstart", onTouchStart);
        window.removeEventListener("touchend", onTouchEnd);
      } else {
        window.removeEventListener("mousemove", onMouseMove);
      }
    };
  }, []);

  const handleFaceClick = (path) => {
    navigate(path);
  };

  return (
    <div className="cube-container kode-mono-2p-regular">
      <div className="cube" ref={cubeRef}>
        <div className="face front" onClick={() => handleFaceClick("/experiment9")}><span>Experiment 9</span></div>
        <div className="face back" onClick={() => handleFaceClick("/inscriptions")}><span>Inscriptions</span></div>
        <div className="face left" onClick={() => handleFaceClick("/artwork")}><span>artwork</span></div>
        <div className="face right" onClick={() => handleFaceClick("/info")}><span>Studio Info</span></div>
        <div className="face top" onClick={() => handleFaceClick("/mixinglab")}><span>Mixing Lab</span></div>
        <div className="face bottom" onClick={() => handleFaceClick("/holdings")}><span>Holdings</span></div>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <div>
      <MatrixEffect />
      <Cube />
    </div>
  );
};

export default Home;
